<template>
  <div>
    <a class="nav-link arrow-none waves-light waves-effect" data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false" @click="Model = !Model">
      <slot name="button">
      </slot>
    </a>
  </div>
  <div class="side-bar right-bar" :class="{ 'show' : Model}">
    <div class="nicescroll" tabindex="5000" style="overflow-y: auto; outline: none;" @click="Model = !Model">
      <ul class="nav nav-pills nav-justified text-xs-center">
        <li class="nav-item">
          <router-link to="/System/operate-record" class="nav-link active">
            {{ $t("HEADER.OPERATERECORD") }}
          </router-link>
        </li>
      </ul>
      <slot name="rightbar" ></slot>
    </div>
  </div>
  <slot></slot>
</template>
<script>
import { computed } from 'vue';
export default {
  name: "RightBar",
  props: {
    model: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val),
    });
    return {
      Model
    };
  },
  mounted() {
    window.addEventListener('click', this.clickOther)
  },
  unmounted() {
    window.removeEventListener('click', this.clickOther)
  },
  methods: {
    clickOther(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.Model = false
      }
    }
  }
};
</script>