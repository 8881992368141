<template>
  <div class="AppLoaging">
    <div class="Loading text-center">
      <img src="logo.png">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>  
    </div>
  </div>
</template>

<style scope>
  .AppLoaging {
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    height: 100%;
    z-index: 9999;
    top: 0;
  }
  .AppLoaging .Loading {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: unset;
    transition: unset;
    background-image: unset;
    background-color: unset;
    border-width: unset;
  }
  .Loading img {
    display: flex;
  }
</style>

<script>
export default {
  name: "AppLoading"
};
</script>