<template>
  <div class="progress" v-if="getLoadingbar != 0">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="getLoadingbar" aria-valuemin="0" aria-valuemax="100" :style="`width: ${getLoadingbar}%`"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "LoadingBar",
  computed: {
    ...mapGetters([
      "getLoadingbar"
    ])
  }
};
</script>