<template>
	<transition name="fade">
		<AppLoading v-if="AppLoading"></AppLoading>
	</transition>
		<section class="section" v-if="!AppLoading">
			<MDBSideNav
				v-model="sidenavScroll"
				mode="side"
				contentSelector="#content"
				:backdrop="true"
				id="navbarExample01"
				:modeBreakpoint="modeBreakpoint"
				:sidenavWidth="sidenavWidth"
			>
				<MDBSideNavMenu>
					<div class="text-center">
						<router-link to="/Dashboard">
							<img class="side-logo" src="logo.png">
						</router-link>
						<hr class="m-0" />
					</div>
					<template v-for="(route, i) in getMenu" :key="i">
						<MDBSideNavItem v-if="route.children" :title="$t('HEADER.' + route.text)" :icon="route.icon" collapse>
							<template v-for="(children , j) in route.children" :key="j">
								<MDBSideNavLink :to="children.link">
									<MDBIcon iconStyle="far"  :icon="children.icon" class="fa-fw me-3" />
									<span>{{ $t("HEADER." + children.text) }}</span>
								</MDBSideNavLink>
							</template>
						</MDBSideNavItem>
						<!-- <MDBSideNavItem v-else>
							<MDBSideNavLink :to="route.link">
								<MDBIcon iconStyle="far" :icon="route.icon" class="fa-fw me-3" />
								<span>{{ $t("HEADER." + route.text) }}</span>
							</MDBSideNavLink>
						</MDBSideNavItem> -->
					</template>
				</MDBSideNavMenu>
			</MDBSideNav>
			<div id="content">
				<header>
					<MDBNavbar expand="lg" light container class="shadow-2 fixed-top topbar" id="mdb-navbar">
						<MDBNavbarToggler target="#navbarExample01" @click="sidenavScroll = !sidenavScroll " ></MDBNavbarToggler>
						<MDBNavbarNav collapse="navbarExample01" class="mb-2 mb-lg-0">
							<MDBNavbarItem to="#">
							</MDBNavbarItem>
						</MDBNavbarNav>
						<ul class="list-inline float-right mb-0">
							<!-- <li class="list-inline-item dropdown notification-list">
								<a class="nav-link arrow-none waves-light waves-effect" target="_blank" href="/" title="查看前台">
									<i class="zmdi zmdi zmdi-eye noti-icon"></i>
								</a>
							</li> -->
							<li class="list-inline-item dropdown notification-list">
								<Dropdown v-model:model="loginrecordDropdown">
									<template v-slot:button>
										<MDBIcon icon="user-circle" iconStyle="fas" class="text-white fs-5" />
									</template>
									<template v-slot:dropdown>
										<div class="dropdown-item noti-title">
											<h5><small>{{ $t("COMMON.LOGINRECORD") }}</small></h5>
										</div>
										<template v-for="(record,k) in currentUserAccountInfo.LoginRecord" :key="k">
											<a href="javascript:void(0);" class="dropdown-item notify-item">
												<div class="notify-icon bg-info"><i class="fa fa-user"></i></div>
												<p class="notify-details">鼎聚網路-登入管理系統<small class="text-muted">{{ record.time }}</small></p>
												<p class="notify-details"><small class="text-muted">{{ record.desc }}</small></p>
											</a>
										</template>
										<router-link to="/System/login-record">
											<a class="text-center dropdown-item notify-item notify-all mb-1">
												{{ $t("COMMON.ALLRECORD") }}
											</a>
										</router-link>
									</template>
								</Dropdown>
							</li>
							<li class="list-inline-item notification-list">
								<RightBar v-model:model="operateRecord">
									<template v-slot:button>
										<MDBIcon icon="bars" iconStyle="fas" class="text-white fs-5" />
									</template>
									<template v-slot:rightbar>
										<div class="tab-content">
											<div class="tab-pane fade  active show" id="actionhistory">
												<div class="timeline-2">
													<template v-for="(record,k) in currentUserAccountInfo.OperateRecord" :key="k">
														<div class="time-item">
															<div class="item-info">
																<small class="text-muted">{{ record.time }}</small>
																<p><strong>{{ record.desc }}</strong></p>
															</div>
														</div>
													</template>
												</div>
												<router-link to="/System/operate-record">
													<button type="button" class="btn btn-block btn-sm btn-info">{{ $t("COMMON.ALLRECORD") }}</button>
												</router-link>
											</div>
										</div>
									</template>
								</RightBar>
							</li>
							<li class="list-inline-item dropdown notification-list">
								<Dropdown v-model:model="personalDropdown">
									<template v-slot:button>
										<MDBIcon icon="cog" iconStyle="fas" class="text-white fs-5" />	
									</template>
									<template v-slot:dropdown>
										<a href="javascript:void(0);" class="dropdown-item notify-item" @click="changePassword = true">
											<i class="fas fa-key me-2"></i><span>{{ $t("COMMON.CHANGEPASSWORD") }}</span>
										</a>
										<!-- <router-link to="/System/Managers">
											<a href="javascript:void(0);" class="dropdown-item notify-item" >
												<i class="fas fa-cog me-2"></i><span>{{ $t("HEADER.MANAGERS") }}</span>
											</a>
										</router-link> -->
										<a href="javascript:void(0);" class="dropdown-item notify-item" @click="onLogout()">
											<i class="fas fa-power-off me-2"></i><span>{{ $t("QUICKUSER.SIGNOUT") }}</span>
										</a>
									</template>
								</Dropdown>
							</li>
						</ul>
					</MDBNavbar>
				</header>
				<main>
					<transition name="fade">
						<LoadingBar></LoadingBar>
					</transition>
					<router-view />	
				</main>
			</div>
		</section>
  <ChangePassword v-model="changePassword" :uuid="currentUserAccountInfo.user.uuid"></ChangePassword>
</template>

<style scope>
	#mdb-navbar {
		height: 58px;
	}
	main {
		margin: 58px 0px 0px 0px;
    overflow-y: auto;
    height: 91vh;
	}
	.side-logo {
		width: 50%;
    padding: 15px 30px;
	}
	.user-dropdown {
		padding: 0.5rem ;
	}
	.topbar {
		background-color: #2b3d51;
	}
	.section {
		background-color: #f5f5f5;
	}
	.dropdown ul {
		position: absolute;
    right: 0;
    background-color: #fff;
    border-radius: 5px;
    list-style: none;
    padding: 0;
    top: 60px;
    box-shadow: 0px 0px 0px 1px rgb(152 152 152 / 20%);
    font-size: 15px;
	}
	.dropdown-item {
		border-radius: 5px;
	}
	.dropdown ul li{
		display: flex;
	}
	.dropdown ul {
		display: none;
	}
	.dropdown {
		position:relative;
	}

	.dropdown-lg {
    width: 280px;
	}
	div.dropdown-menu-right {
    right: 0;
    left: auto;
	}
	.notification-list .noti-title {
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: #64b0f2;
    margin: -6px -1px 0px -1px;
    width: auto;
    padding: 12px 20px;
	}
	.notification-list .notify-item .notify-icon {
    float: left;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    color: #ffffff;
	}
	.notification-list .notify-item .notify-details {
    margin-bottom: 0;
    overflow: hidden;
    margin-left: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
	}
	.notification-list .notify-item {
    padding: 10px 20px;
	}
	.notification-list .notify-item .notify-details small {
    display: block;
	}
	.notification-list .noti-title h5 {
    color: #ffffff;
    margin: 0;
	}
	.notification-list .notify-all {
    border-radius: 0 0 0.25rem 0.25rem;
    margin: 0 0 -5px 0;
    background-color: #eceeef;
	}

	.side-bar.right-bar {
    float: right !important;
    right: -266px;
    /*right: 0px;*/
    top: 58px;
  }
  .right-bar {
    background: #ffffff !important;
  }
  .side-bar {
    -moz-transition: all 200ms ease-out;
    -webkit-transition: all 200ms ease-out;
    background-color: #ffffff;
    box-shadow: 0 0px 24px 0 rgb(0 0 0 / 6%), 0 1px 0px 0 rgb(0 0 0 / 2%);
    display: block;
    float: left;
    height: 100%;
    position: fixed;
    transition: all 200ms ease-out;
    width: 240px;
    z-index: 9;
  }
  .right-bar .nicescroll {
    height: 88%;
  }
  .nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
    background-color: #039cfd;
  }
  .right-bar .tab-content {
    padding: 20px;
  }
  .timeline-2 {
    border-left: 2px solid #64b0f2;
    position: relative;
  }
  .time-item {
    border-color: #dee5e7;
    padding-bottom: 10px;
    position: relative;
  }
  .item-info {
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .time-item:before {
    content: " ";
    display: table;
  }
  .timeline-2 .time-item:after {
    background-color: #ffffff;
    border-color: #64b0f2;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: '';
    height: 10px;
    left: 0;
    margin-left: -6px;
    position: absolute;
    top: 5px;
    width: 10px;
  }
  .right-bar .nav-pills .nav-link {
    display: block;
    padding: .5em 1em;
    margin: .5rem 0;
  }
  .right-bar .nav-pills {
    margin: unset;
  }
  .right-bar.show {
    right: 0;
  }
  .form .Loading {
    margin-left: 5px;
    position: unset;
    height: 1rem;
    width: 1rem;
  }
  .swal2-styled {
		margin-right: 25px!important;
  }
</style>

<script>
import { 
					MDBSideNav,
					MDBSideNavMenu,
					MDBSideNavItem,
					MDBIcon,
					MDBSideNavLink,
					MDBNavbar,
					MDBNavbarToggler,
					MDBNavbarNav,
					MDBNavbarItem,
				} from 'mdb-vue-ui-kit';

import ChangePassword from '@/components/ChangePassword';
import Dropdown from '@/components/Dropdown';
import RightBar from '@/components/RightBar';
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import AppLoading from '@/components/Elements/AppLoading';
import LoadingBar from '@/components/Elements/LoadingBar';
export default {
  name: "Layout",
  components: {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBIcon,
    MDBSideNavLink,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    ChangePassword,
    Dropdown,
    RightBar,
    AppLoading,
    LoadingBar
  },
  setup() {
		const sidenavScroll = ref(false);
		const personalDropdown = ref(false);
		const languageDropdown = ref(false);
		const changePassword = ref(false);
		const loginrecordDropdown = ref(false);
		const operateRecord = ref(false);
		const routes = [];
		const modeBreakpoint = 992;
		const sidenavWidth = 220;
		const AppLoading = ref(true);
		return {
			sidenavScroll,
			routes,
			personalDropdown,
			languageDropdown,
			changePassword,
			modeBreakpoint,
			loginrecordDropdown,
			sidenavWidth,
			operateRecord,
			AppLoading
		}
	},
	computed: {
    ...mapGetters([
      "isAuthenticated",
      "getMenu",
      "currentUserAccountInfo"
    ])
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "Login" });
    }
    Promise.all(
      [this.$store.dispatch("getAccountMenu")],
    ).then(() => {
			this.AppLoading = false;
    });
  },
  methods: {
		onLogout() {
      this.$store
        .dispatch("logout")
        .then(() => this.$router.push({ name: "Login" }));
    }
  },
  
};
</script>