<template>
  <div>
    <a class="nav-link arrow-none waves-light waves-effect" data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false" @click="Model = !Model">
      <slot name="button">
      </slot>
    </a>
  </div>
  <div class="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-md" aria-labelledby="Preview" :class="{ 'show' : Model}" @click="Model = !Model">
    <slot name="dropdown" >
    </slot>
  </div>
  <slot></slot>
</template>
<script>
import { computed } from 'vue';
export default {
  name: "Dropdown",
  props: {
    model: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val),
    });

    return {
      Model
    };
  },
  mounted() {
    window.addEventListener('click', this.clickOther)
  },
  unmounted() {
    window.removeEventListener('click', this.clickOther)
  },
  methods: {
    clickOther(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.Model = false
      }
    }
  }
};
</script>