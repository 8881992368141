<template>
  <MDBModal
    tabindex="-1"
    v-model="Model"
  >
    <MDBContainer>
      <form @submit="Submit">
        <MDBModalHeader>
          <MDBModalTitle id="exampleModalLabel">{{ $t("COMMON.CHANGEPASSWORD")}}</MDBModalTitle>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            <MDBRow class="g-3">
              <MDBCol md="12">
                <span class="text-danger change-password-notice">{{ $t("PAGES.CHANGEPASSWORD.NOTICE")}}</span>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t("COMMON.OLDPASSWORD")}}<span class="text-danger">*</span></label>
                  <input type="password" class="form-control" :placeholder="$t('COMMON.OLDPASSWORD')" v-model="Form.old_password.value">
                </div>
                <span class="text-danger">{{ old_passwordError }}</span>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PASSWORD') }}<span class="text-danger">*</span></label>
                  <input type="password" class="form-control" name="password" :placeholder="$t('COMMON.PASSWORD')" v-model="Form.password.value">
                  <span class="text-danger">{{ passwordError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PASSWORDCONFIRM') }}<span class="text-danger">*</span></label>
                  <input type="password" class="form-control" name="password_confirmation" :placeholder="$t('COMMON.PASSWORDCONFIRM')" v-model="Form.password_confirmation.value">
                  <span class="text-danger">{{ password_confirmationError }}</span>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" type="submit">{{ $t("COMMON.CONFIRM")}}</MDBBtn>
          <MDBBtn type="button" @click="Model = false">{{ $t("COMMON.CLOSE")}}</MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBContainer>
  </MDBModal>
</template>

<style scope>
  .change-password-notice {
    font-size: 15px;
  }
</style>

<script>
import { MDBContainer, MDBRow, MDBBtn, MDBCol, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed } from "vue";
import { useForm, useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';

export default {
  name: "ChangePassword",
  components: {
    MDBContainer,
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      typeof: String,
      default: ""
    }
  },
  setup(props, { emit }) {

    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val),
    });

    const i18n = useI18n();

    const basicSchema = {
      old_password: yup.string().required().min(6).max(12).label(i18n.t("COMMON.OLDPASSWORD")),
      password: yup.string().required().min(6).max(12).label(i18n.t("COMMON.PASSWORD")),
      password_confirmation: yup.string().min(6).max(12).required().label(i18n.t("COMMON.PASSWORDCONFIRM"))
    };

    let ExtendSchema = {};
    
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema
    });

    const { value: old_password, errorMessage: old_passwordError } = useField('old_password');
    const { value: password, errorMessage: passwordError } = useField('password');
    const { value: password_confirmation, errorMessage: password_confirmationError } = useField('password_confirmation');

    const Submit = handleSubmit(values => {
      ApiService.update("/Admin/Admin/ChangePassword", props.uuid, values).then(response => {
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          resetForm();
          Model.value = false;
        }
      });
      return false;
    });

    return {
      Model,
      Form: {
        old_password,
        password,
        password_confirmation
      },
      Submit,
      old_passwordError,
      passwordError,
      password_confirmationError
    };
  }
};
</script>